import { Box, Flex, Avatar, Text, Badge, Button } from '@chakra-ui/react';
import { FC } from 'react';
import { FaBuilding } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../../../../../../shared/utils/client';
import './card.scss'
import { useTranslation } from 'react-i18next';
interface UserCardProps {
  user: any;
}
const UserProfile: FC<UserCardProps> = ({ user }) => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation()

  const screenWidth = window.screen.width;

  return (
    <Box
      boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06);"
      p="2rem"
      rounded="md"
      w="100%"
      _hover={{ cursor: 'pointer', transform: 'translateY(-2px)', boxShadow: 'lg' }}
    >
      <Flex flexDirection={screenWidth <= 768 ? 'column' : 'row'} gap="2rem" alignItems={'start'}>
        <Avatar
          size="xl"
          boxSize="6.5rem"
          src={user?.profileCoverImage ? `${baseURL}/files/${user.profileCoverImage}` : 'default-avatar.png'}
        />
        <Flex minH={'6.5rem'} gap=".5rem" justify={"space-between"} flexGrow={1}>
          <Flex direction="column">
            <Flex gap=".5rem" alignItems="center">
              <Text fontSize="l" as={'h2'} color={"#2a4d5f"} fontWeight="bold">
                {user?.firstName + ' ' + user?.lastName}
              </Text>
            </Flex>

            {user?.jobTitle && <Text fontSize="l" as={'h2'} color={'#88b7d0'} fontWeight="bold"> {user?.jobTitle} </Text>}
            <Flex direction="row" align="center">
              <Text ml={1} color="#2a4d5f" fontWeight={'400'}>
                {user?.companyCity + ' ' + user?.companyStreet + ' ' + user?.companyHouseNumber}
              </Text>
            </Flex>
            <Text
              sx={{ mt: 4, color: '#2a4d5f', fontWeight: '400' }}
              noOfLines={3}
              dangerouslySetInnerHTML={{ __html: user?.aboutMe }}
            ></Text>
            {user.specialties && user.specialties.length ? (
              <>
                <Text fontSize="l" as={'h2'} mt={3} mb={2} color={'#88b7d0'} fontWeight="bold"> {translate("profile.settings.professionalDetails.specialties.title")} </Text>
                <Flex gap="1" minH="2rem">
                  {user.specialties.map((data: string, index: number) => (
                    <Badge className='special-badge' key={`${data}${index}`} colorScheme="gray" color="#2a4d5f" rounded="md" px={4} fontWeight={'600'} py="2">
                      {data}
                    </Badge>
                  ))}
                </Flex>
              </>) : null}
          </Flex>
          <Flex direction={'column'} justifyContent={'end'}>
            <Button
              size="sm"
              width={'max-content'}
              minW={'max-content'}
              flexGrow={0}
              bg={'primary.600'}
              mt={'10px'}
              color={'white'}
              onClick={() => navigate(`${process.env.PUBLIC_URL}/profile/${user?.id}`)}
              rounded={'md'}
              _hover={{
                transform: 'translateY(-2px)',
                boxShadow: 'lg',
              }}
            >
              {translate('common.showDetails')}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default UserProfile;
