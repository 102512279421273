import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import BaseContainer from '../../shared/components/container/container';
import Footer from '../../shared/layout/footer';
import PrivateHeader from '../../shared/layout/private-header';
import ProfileHeader from './components/profile-header/profile-header';
import { getPrivateTitle } from './infrastructure/selectors';
import { isLoggedInUser } from '../../shared/utils/isLoggedInUser';
import { useProfileApi } from './profile/hooks/useProfileApi';
import { getCurrentUser } from './profile/infrastructure/selector';
import { Spinner } from '../../shared/components/spinner/spinner';
import SetupProfileModal from './setupProfileModal/setupProfileModal.container';
import { useChargeApi } from './profile/hooks/useChargeApi';
import { Flex } from '@chakra-ui/react';
import { getFirstLogin } from '../authentification/infrastructure/selectors';
interface LoginProps {}

const PrivateContainer: FC<LoginProps> = () => {
  const { getUserMe } = useProfileApi();
  const { getCharge } = useChargeApi();
  const { id } = useParams<{ id: string }>();

  const firstLogin = useSelector(getFirstLogin);

  const currentUser = useSelector(getCurrentUser);
  const privateTitle = useSelector(getPrivateTitle);
  useEffect(() => {
    if (isLoggedInUser()) {
      getUserMe();
      getCharge();
    }
  }, []);

  if (!isLoggedInUser()) {
    return <Navigate to={`/profile/${id}`} />;
  }

  if (!currentUser) {
    return <Spinner />;
  }  

  return (
    <Flex direction="column" minH="100vh">
      <PrivateHeader />
      {privateTitle && privateTitle === 'Profile' && <ProfileHeader />}
      {privateTitle && privateTitle === 'ProfilePublic' && <ProfileHeader isPublic />}
      <BaseContainer centerContent maxWidth={'100%'}>
        <SetupProfileModal defaultIsOpen={false} />
        <Outlet />
      </BaseContainer>
      <Footer />
    </Flex>
  );
};

export default PrivateContainer;
