import { Card } from '@chakra-ui/card';
import { Box, Divider, Heading, Text } from '@chakra-ui/layout';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FormattedMessage from '../../../../shared/components/translation/formattedMessage';
import { getCurrentUser } from '../../profile/infrastructure/selector';
import { selectActiveUser } from '../../connect/infrastructure/selectors';

interface ProfileInfoProps {}

const ProfileInfo: FC<ProfileInfoProps> = () => {
  const currentUser = useSelector(getCurrentUser);
  const activeUser = useSelector(selectActiveUser);
  const [user, setUser] = useState<any>(null);  

  useEffect(() => {
    if (activeUser) {
      setUser(activeUser);
    } else {
      setUser(currentUser);
    }
  }, [activeUser]);

  return (
    <Card width="100%">
      <Heading size="md" textAlign="left" padding={25}>
        <FormattedMessage translationKey={'profile.info.title'} />
      </Heading>
      <Divider borderColor="gray.200" />
      <Box paddingLeft="5" paddingY="2">
        <Text size="2xs" color="gray.400">
          <FormattedMessage translationKey={'profile.province.label'} />
        </Text>
        <Text fontSize={18} fontWeight="500">
          {user?.professionalAddress ?? '_'}
        </Text>
        <Text color="gray.400" marginTop="3">
          <FormattedMessage translationKey={'profile.LidSinds.label'} />
        </Text>
        <Text fontSize={18} fontWeight="500">
          {moment(user?.created_at).format('DD MMMM YYYY') ?? '_'}
        </Text>
        
      </Box>
    </Card>
  );
};

export default ProfileInfo;
