import { Avatar, Button, IconButton, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, Container, Flex, Stack, Text, Center } from '@chakra-ui/layout';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveUser } from 'app/features/private/connect/infrastructure/selectors';
import { baseURL } from 'app/shared/utils/client';
import BackToDashboard from 'app/shared/components/back-to-dashboard/back-to-dashboard';
import LanguageSelect from 'app/shared/components/language-select/LanguageSelect';
import { FaSignInAlt, FaPhoneAlt } from 'react-icons/fa';
import './ProfileHeaderPublic.scss';

interface ProfileHeaderProps {
  isPublic?: boolean;
}

const ProfileHeader: FC<ProfileHeaderProps> = ({ isPublic }) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const activeUser = useSelector(selectActiveUser);

  const getProfileInfo = () => {
    return (
      <Stack alignItems="center">
        <Box>
          <Text color="white" fontSize="28">
            {activeUser?.firstName} {activeUser?.lastName}
          </Text>
          {activeUser?.jobTitle &&
            (<Text color="white" fontSize="32">
              {activeUser?.jobTitle}
            </Text>)
          }
          {activeUser?.phoneNumber && (
            <Button
              leftIcon={<FaPhoneAlt />}
              borderRadius="md"
              width="200px"
              textAlign="center"
              px="10"
              py="1"
              bgColor="white"
            >
              <Text color="gray.600">{activeUser?.phoneNumber || translate('connect.explore.phone.notAvailable')}</Text>
            </Button>
          )}
          <Text color="white" fontSize="18" maxWidth="200" fontWeight="300" marginTop="5">
            {activeUser?.professionalAddress}
          </Text>
          <Text color="white" fontSize="15">
            {activeUser?.type}
          </Text>

        </Box>
      </Stack>
    );
  };

  return (
    <Box bg={useColorModeValue('primary.600', 'gray.900')} width="100%" height="330" marginBottom="20" className='header-public-profil-details'>
      <Container h="100%" maxW="100%">
        <Flex float="left" mt="0.8rem" alignItems={'center'}>
          <BackToDashboard label="header.back_to_search" redirection="/zoeken" user={`${activeUser?.firstName} ${activeUser?.lastName}`} />
        </Flex>
        <Flex float="right" mt="0.8rem">
          <Stack direction={'row'} spacing={4}>
            <LanguageSelect />
            <Tooltip label={translate('authentification.login.form.button.login.label')}>
              <IconButton
                isRound={true}
                variant="solid"
                fontSize="20px"
                icon={<FaSignInAlt />}
                onClick={() => navigate('/login')}
                aria-label={translate('authentification.login.form.button.login.label')}
              />
            </Tooltip>
          </Stack>
        </Flex>

        {/* <Stack alignItems="center">{getProfileInfo()}</Stack> */}

        <Stack width="100%" paddingTop={'5rem'} marginBottom="20">
          <Flex justifyContent="space-between" paddingLeft="10" paddingRight="10">
            {getProfileInfo()}
          </Flex>
          <Center w="100%">
            {activeUser?.firstName && (
              <Avatar
                bg="rgba(99, 83, 59, 1)"
                src={`${baseURL}/files/${activeUser?.profileCoverImage}`}
                name={`${activeUser?.firstName} ${activeUser?.lastName}`}
                size={'2xl'}
                boxSize="11rem"
              />
            )}
          </Center>
        </Stack>
      </Container>
    </Box>
  );
};

export default ProfileHeader;
