import { FC, useEffect, useState } from 'react';
import { Box, Spinner, Stack } from '@chakra-ui/react';
import { useProfileApi } from '../../../../../profile/hooks/useProfileApi';
import { useDebouncedCallback } from 'use-debounce';
import Select from 'react-select'
import { useTranslation } from 'react-i18next';

interface SearchSpecialtiesProps {
  onChange: (specialties: string[]) => void;
  width?: string;
  size?: string;
  resetSpecialties?: boolean;
  setResetSpecialties?: (v: boolean) => void;
  handleKeyDown?: (e: any) => void;
}

const SearchSpecialties: FC<SearchSpecialtiesProps> = ({ onChange, width = '75%', resetSpecialties, setResetSpecialties, handleKeyDown }) => {
  const { t: translate } = useTranslation();
  const [suggestedResults, setSuggestedResults] = useState<any>([]);
  const [specialties, setSpecialties] = useState<any[]>([]);
  const [valuesSelected, setValuesSelected] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { searchSpeciality } = useProfileApi();

  useEffect(() => {
    if (resetSpecialties && setResetSpecialties) {
      setResetSpecialties(false);
      setSpecialties([]);
    }
  }, [resetSpecialties]);

  const handleSearch = async (query: string) => {
    setIsLoading(true);
    const result = await searchSpeciality(query);
    const transformedResult = result.map((name) => ({ value: name, label: name }));
    setSuggestedResults(transformedResult);
    setIsLoading(false);
  };

  const debounced = useDebouncedCallback(handleSearch, 500);
  const handleInputChange = (event: string) => {
    const query = event;
    if (query.length) {
      debounced(query);
    } else {
      setSuggestedResults([]);
    }
  };

  useEffect(() => {
    onChange(specialties);
  }, [specialties]);

  return (
    <Stack width={width}>
      <Box position="relative">
        <Select
          isClearable
          isSearchable
          isMulti
          value={valuesSelected}
          noOptionsMessage={() => translate("common.filters.empty")}
          placeholder={translate("connect.explore.filter.followingSpecialities_placeholder")}
          options={suggestedResults}
          onInputChange={(e: string) => handleInputChange(e)}
          onChange={(e) => {
            e && setValuesSelected(e);
            setSpecialties(e && e.map(el => el.value))
          }}
          onKeyDown={handleKeyDown}
        />
        {isLoading && (
          <Box position="absolute" top="50%" right="1rem" transform="translateY(-50%)">
            <Spinner size="sm" />
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default SearchSpecialties;
