import { FC, useState, useRef, useEffect } from 'react';
import SearchSpecialties from './search-specialties/search-specialties';
import { useConnectApi } from '../../../hooks/useConnectApi';
import { FilterRequestModel } from '../../../infrastructure/domain/FilterRequest.model';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectConnectLimit, selectConnectPage } from '../../../infrastructure/selectors';
import { IPaginationConfig } from '../../../../../../shared/types/pagination-config.type';
import { Box, Text, Input, AlertDialog, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, useDisclosure, Button, Switch, FormControl, FormLabel, Flex } from '@chakra-ui/react';
import './filters.scss';
import { useGeolocated } from 'react-geolocated';
import Select from 'react-select'

interface PublicFiltersProps { }

const PublicFilters: FC<PublicFiltersProps> = () => {
  const { t: translate } = useTranslation();
  const [useFilter, setUseFilter] = useState<boolean>(false)
  const [errorName, setErrorName] = useState<boolean>(false)
  const { isOpen, onClose } = useDisclosure();
  const dataGeolocation = [
    { value: 0, label: translate('connect.explore.filter.range.allDistance.option') },
    { value: 3000, label: '< 3 km' },
    { value: 5000, label: '< 5 km' },
    { value: 10000, label: '< 10 km' },
    { value: 15000, label: '< 15 km' },
    { value: 25000, label: '< 25 km' },
    { value: 50000, label: '< 50 km' },
    { value: 75000, label: '< 75 km' },
  ];
  const { isGeolocationAvailable, isGeolocationEnabled, coords } = useGeolocated();
  const cancelRef = useRef(null);
  const [query, setQuery] = useState<FilterRequestModel>({
    location: '',
    postalCode: '',
    specialties: [],
    name: '',
    longitude: coords ? coords.longitude : 0,
    latitude: coords ? coords.latitude : 0,
    distance: 0,
  });
  const page = useSelector(selectConnectPage);
  const limit = useSelector(selectConnectLimit);
  const { filterUsers, getAllUsers } = useConnectApi();
  const debounced = useDebouncedCallback(filterUsers, 1000);

  useEffect(() => {
    setQuery({
      ...query,
      longitude: coords ? coords.longitude : 0,
      latitude: coords ? coords.latitude : 0,
    });
  }, [isGeolocationAvailable, isGeolocationEnabled, coords])
  const onLocationChange = (location: string) => {
    setQuery({
      ...query,
      location,
    });
  };
  const onNameChange = (name: string) => {
    setErrorName(false)
    setQuery({
      ...query,
      name,
    });
  };

  const onSpecialtiesChange = (specialties: string[]) => {
    setQuery({
      ...query,
      specialties,
    });
  };

  const onApplyFiltersClick = () => {
    const paginationConfig: IPaginationConfig = {
      page,
      limit,
    };
    if ((query?.name !== '' && query?.name?.length < 3)) {
      setErrorName(true)
    } else if (query?.location || query?.specialties?.length || query?.distance || query?.name) {
      debounced(query, paginationConfig);
    } else {
      getAllUsers(paginationConfig, false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onApplyFiltersClick();
    }
  };

  return (
    <>
      <div className="custom-container pb-2">
        <Box bg='white' display={'flex'} flexDirection={window.screen.width > 550 ? 'row' : 'column'} justifyContent={'space-between'} >
          <Text as="b" className="title">
            {translate('connect.explore.filter.publicFilterTitle')}
          </Text>
          <FormControl display='flex' alignItems='center' width={'max-content'} className='use-filter-switch-box'>
            <FormLabel htmlFor='email-alerts' mb='0'>
              {translate('connect.explore.filter.useFilter')}
            </FormLabel>
            <span className={`${useFilter ? 'yes-label' : 'no-label'} switch-icon-left use-filter-custom-label text-capitalize`}>{useFilter ? translate("common.yes") : translate("common.no")}</span>
            <Switch onChange={(e) => setUseFilter(e.target.checked)} id='email-alerts' />
          </FormControl>
        </Box>
        <Box bg="white" rounded="md" display={useFilter ? "flex" : "none"} style={{ alignItems: 'center', flexWrap: 'wrap' }}>
          <Flex direction={'column'}>
          <Text as="b" className="title" mt={'10px'}>
            {translate('connect.explore.filter.lookingForTherapistName')}
          </Text>
          <Box mr={'10px'} mt={'10px'}>
            <Input
              size="sm"
              name="name"
              placeholder={translate('connect.explore.filter.lookingForTherapistName_placeholder') || ''}
              type="text"
              onChange={(e) => onNameChange(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            {errorName && <Text as={'b'} fontSize={'x-small'} color={'#e53e3e'}>{translate('connect.explore.filter.lookingForTherapistName.error')}</Text>}
          </Box>
          </Flex>
          <Flex direction={'column'}>
          <Text as="b" className="title" mt={'10px'}>
            {translate('connect.explore.filter.followingSpecialities')}
          </Text>
          <Box mr={'10px'} mt={'10px'}>
            <SearchSpecialties onChange={(specialties) => onSpecialtiesChange(specialties)} width="100%" size="sm" handleKeyDown={handleKeyDown} />
          </Box>
          </Flex>
          <Flex direction={'column'}>
          <Text as="b" className="title" mt={'10px'}>
            {translate('connect.explore.filter.range')}
          </Text>
          <Box mr={'10px'} mt={'10px'}>
            <Select
              isClearable
              options={dataGeolocation}
              placeholder={translate('connect.explore.filter.range_placeholder')}
              onChange={(e) => setQuery({ ...query, distance: e && e.value })}
              onKeyDown={handleKeyDown}
            />
          </Box>
          </Flex>
          <Flex direction={'column'}>
          <Text as="b" className="title" mt={'10px'}>
            {translate('connect.explore.filter.nearCity')}
          </Text>
          <Box mr={'10px'} mt={'10px'}>
            <Input
              size="sm"
              name="role"
              placeholder={translate('connect.explore.filter.nearCity_placeholder') || ''}
              type="text"
              onChange={(e) => onLocationChange(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </Box>
          </Flex>
        </Box>
        <Box display={useFilter ? 'block' : 'none'} py="0.5rem" style={{ textAlign: 'right' }}>
          <Button
            size="sm"
            flexGrow={0}
            bg={'primary.600'}
            color={'white'}
            rounded={'md'}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
            }}
            onClick={onApplyFiltersClick}
          >
            {translate('common.apply_filters')}
          </Button>
        </Box>
      </div>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {translate('common.not_supported_browser')}
            </AlertDialogHeader>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {translate('connect.explore.buttons.close')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default PublicFilters;
